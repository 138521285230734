<template>
  <div>
    <client-only>
      <div v-if="isLoading">
        <full-screen-loading />
      </div>
      <div v-else>
        <slot></slot>
      </div>
    </client-only>
  </div>
</template>

<script setup>
import { useTokenStore } from "~/stores/token";
const tokenStore = useTokenStore();
const { isLoading, isValid } = storeToRefs(tokenStore);

const router = useRouter();
watchEffect(() => {
  if ((!isLoading.value && !isValid.value) || isValid.value === null) {
    router.push("/unauthorized");
  }
});
</script>
